// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authServiceUrl: 'https://landing-martin.internal.sparbankensyd.se/service',
  ssAPIUrl: 'https://landing-martin.internal.sparbankensyd.se/api',
  domain: 'landing-martin.internal.sparbankensyd.se',
  // settingsServiceUrl: 'https://api-settings-martin.internal.sparbankensyd.se/settings',
  settingsServiceUrl: 'https://landing-martin.internal.sparbankensyd.se/settings',
  documentUrl: 'https://landing-martin.internal.sparbankensyd.se/doc'
}
