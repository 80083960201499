/**
 * Gitlab projects for deploy
 */
export interface IGitLabProject {
  name: string
  id: string | number
  description: string
}

/**
 * Interface for Gitlab project, which is in our database
 */
export interface GitProject {
  projectName: string
  projectKey: string
  projectDescription: string
  itemId: string
  stagedVersion?: string

  /**
   * The path and name of the 'package.json' that is used to
   * set version when staging.
   */
  packageJson: string
}

/**
 * Random variables
 */
export const HOME_ROUTE_PATH = 'home'
export const LOGIN_ROUTE_PATH = 'login'
export const ADMIN_ROUTE_PATH = 'admin'
export const DEPLOY_ROUTE_PATH = 'deploy'


export const IS_LANDING_USER_ROLE = 'landingAppUser'
export const IS_LANDING_ADMIN_ROLE = 'landingAppAdmin'
export const IS_USER_ADMIN_ROLE = 'spbCommonUserAdmin'
export const IS_USER_DEVELOPER_ROLE = 'developer'
export const IS_DOCUMENT_SUPER_USER_ROLE = 'userDocSuperUser'

/**
 * Change name in logs details template
 */
export const changeName: any = {
  addedRole: 'Adderad Roll',
  name: 'Namn',
  email: 'Email',
  deletedRole: 'Raderad Roll',
  roleDescription: 'Beskrivning av roll',
  roleName: 'Rollens Namn',
  sub: 'Personnummer',
  phone: 'Telefon',
  pet: 'Husdjur',
  office: 'Kontor',
  userType: 'Användartyp',
  key: 'Kod',
  roleKey: 'Rollens Kod',
  siteName: 'Sidans Namn',
  siteKey: 'Sidans Kod',
  roles: 'Roller'
}

/**
 * This is the timer used for closing the snackbar for saved/deleted roles/users.
 */
export const EXTREME_LONG_TIME_OUT = 10000
export const LONG_TIME_OUT = 4000
export const TIME_OUT = 2000
